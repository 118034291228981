module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/forbesye.github.io/forbesye.github.io/node_modules/gatsby-remark-images","id":"9728b562-acf2-5a62-8ffa-b18c994e3521","name":"gatsby-remark-images","version":"6.25.0","modulePath":"/home/runner/work/forbesye.github.io/forbesye.github.io/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":900,"quality":80,"withWebp":{"quality":80}},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/forbesye.github.io/forbesye.github.io","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":900,"quality":80,"withWebp":{"quality":80}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
